// Auto-generated file by generate-icons.js


export default {
  prefix: 'waypoint',
  iconName: 'bisse',
  icon: [
    14,
    14,
    [],
    "",
    'M6 3L1 4.8V11h1V5l3.81-1.53v.9a.73.73 0 0 1-.1-.1.24.24 0 0 0-.38 0 .72.72 0 0 1-.55.25.24.24 0 0 0 0 .49c.27 0 .53-.1.74-.25a1.2 1.2 0 0 0 1.48 0 1.2 1.2 0 0 0 1.48 0c.21.16.47.25.74.25a.24.24 0 0 0 0-.49.72.72 0 0 1-.55-.26.24.24 0 0 0-.38 0 .73.73 0 0 1-.1.1v-.89L12 5v6h1V4.8L8 3v1.47a.7.7 0 0 1-.81-.2.24.24 0 0 0-.37 0 .72.72 0 0 1-.82.2zM4.75 6.5a.24.24 0 0 0-.19.08.72.72 0 0 1-.55.26.24.24 0 0 0 0 .49c.27 0 .53-.09.74-.25.21.16.47.25.74.25h.06c.26 0 .5-.09.7-.24.21.15.46.24.72.24h.06c.26 0 .5-.09.71-.24.2.15.45.24.71.24h.06c.27 0 .53-.09.74-.25.21.16.47.25.74.25a.24.24 0 0 0 0-.49.72.72 0 0 1-.55-.26.24.24 0 0 0-.38 0 .72.72 0 0 1-1.1 0 .24.24 0 0 0-.2-.08h-.02-.03a.24.24 0 0 0-.18.08.72.72 0 0 1-.53.26c-.2 0-.4-.1-.53-.26a.24.24 0 0 0-.18-.08h-.06a.24.24 0 0 0-.18.08.72.72 0 0 1-1.11 0 .24.24 0 0 0-.19-.08zm-.82 2.56a.24.24 0 0 0-.2.08.72.72 0 0 1-.55.26.24.24 0 0 0 0 .5c.28 0 .53-.1.75-.26a1.2 1.2 0 0 0 1.48 0c.2.16.47.25.74.25h.02c.27 0 .52-.09.73-.24.2.15.46.24.73.24h.02c.27 0 .53-.09.74-.25a1.2 1.2 0 0 0 1.49 0c.2.16.46.25.74.25a.24.24 0 0 0 0-.49.72.72 0 0 1-.56-.26.24.24 0 0 0-.37 0 .72.72 0 0 1-1.1 0 .24.24 0 0 0-.37 0 .72.72 0 0 1-1.12 0 .24.24 0 0 0-.19-.08H6.9h-.01a.24.24 0 0 0-.19.08.72.72 0 0 1-1.1 0 .24.24 0 0 0-.37 0 .72.72 0 0 1-1.12 0 .24.24 0 0 0-.18-.08z'
  ]
}