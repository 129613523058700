// Auto-generated file by generate-icons.js


export default {
  prefix: 'activity',
  iconName: 'via_ferrata',
  icon: [
    64,
    64,
    [],
    "",
    'M32 4a28 28 0 1 0 0 56 28 28 0 0 0 0-56zm11.4 3.3C67 21.4 62 51.8 36.4 59c.7-1.4 1.6-3.1 2.5-4.4l.6-.7h-7.8c-.6 0-1-.5-1-1.1 0-.4.1-.7.4-1h-.9c-.3 0-.5 0-.5-.4 0-.7-.3-1.4 0-2.2.2-.8-.2-1.6-.6-2.4a8.3 8.3 0 0 1-1.1-3.7 1 1 0 0 0-.3-.6L26.4 41l-3.6-3c-.7-.6-1.4-1.1-1.9-1.9-1.1-2 .2-4.1.2-4.1.4-.8.3-1.6 0-2.4-1.5-4-.2-6.4-.2-6.4.2-.4.5-.8 0-1.4l-.8-1.7c-.4-.8-.5-1.8-.3-2.6.4-1.3 3-2.1 4.1-1.4.3.2.4.5.6.8l.4.6c.5.4.7 1 .6 1.6 0 .5.2.6.6.4a11 11 0 0 0 3.6-3.5l.6-.6c1.6-1.7 3.5-3 5.3-4.3.5-.3 1.2-1.4 1.8-1.3H42.8l.6-2.6zm-7.1 5.2l-.3.3c-.3.3-.7 0-1.1.3-.9.8-1.3 1.7-2 2.6l-.6.7c-.6.6-1.2 1-1.7 1.7L28 21c-1.1 1-1 2.4-1.5 3.4l.1.4 1.8.9c.8-.4 1.6 0 2.4-.2l3.9-.9 1.8-.2.7.4.1.3h5.8l-.1-.5c-.4-1.6-.5-4.4-.9-6.4s0-4.4 0-4.6l.1-.6h-5c-.3 0-.7-.3-.9-.6zm0 13.4c-.2.4-1 .2-1.3.2-.8-.1-1.5.2-2.3.4-1 .3-1.8.9-3 1h-1c-.7-.2-1.3 0-2-.1v1.7c.2 0 .5-.1.7-.3a4 4 0 0 1 2.8-.7c1 0 1.6.6 1.7 1.5.1 1.3.4 2.5.4 3.8 0 1.3 0 2.7.5 4 .1.2.3.6.5.6.4 0 .6.4.9.6.2.3.4.3.7.3a2 2 0 0 1 1.6.9l.1.3H39l-.1-1.8c0-1.4.7-3.1 3-6a7 7 0 0 0 1.6-4.9h-6.2c-.6 0-1.1-.5-1.1-1v-.5zm-7.1 5.9l-.4.3v.2c-.1.8-.1.8-1 1-.5 0-1.1.3-1.7.4.3 1.2 2 3.8 2.4 4.8.5 1 1 2.2 1.9 3v.4c0 1 .1 1.8.4 2.7.3 1 .3 2 .4 3 0 .5.3 1 .4 1.4 0 .7.5 1 1 1.2l1 .5c.3.2.8.7.8 1H41c.3-.7.5-1.6.5-2.6 0-3.4-.4-2-1.4-3.4-.4-.6-.7-2-1-3.3h-5.5c-.6 0-1-.5-1-1.1 0-.4.1-.8.5-1h-1.7c-.6-1.2 0-2.5-.6-3.7l-.5-1.4c-.5-1-1-2-.9-3.1l-.2-.3z'
  ]
}