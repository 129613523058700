// Auto-generated file by generate-icons.js


export default {
  prefix: 'waypoint',
  iconName: 'base_camp',
  icon: [
    14,
    14,
    [],
    "",
    'M 4.2626124,4 4.14478,4.22071 1.672226,9.58947 H 1.1329 C 1.0594663,9.58947 1,9.68146 1,9.79478 1,9.90837 1.0594663,10 1.1329,10 H 6.6076751 7.3927117 12.867487 C 12.940666,10 13,9.90837 13,9.79478 13,9.68146 12.940534,9.58947 12.867101,9.58947 H 12.327775 L 9.8552209,4.22071 9.7373882,4 9.6191693,4.22071 7.1470017,9.58947 H 6.852999 L 4.3808313,4.22071 Z M 4.2626124,6.90374 4.9514507,9.58947 H 3.5741605 Z M 9.7373882,6.90374 10.425839,9.58947 H 9.0489363 Z'
  ]
}