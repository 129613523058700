// Auto-generated file by generate-icons.js


export default {
  prefix: 'document-type',
  iconName: 'outing',
  icon: [
    64,
    64,
    [],
    "",
    'M 21.654297 4 L 20.226562 9.5957031 C 17.763546 10.08053 15.497747 11.350995 13.685547 13.261719 L 8.609375 11.101562 L 11.394531 16.458984 C 10.287742 18.578547 9.6666696 20.996393 9.5839844 23.5 L 4 26.162109 L 10.007812 27.978516 C 10.519189 30.216937 11.462167 32.314918 12.763672 34.119141 L 10.521484 40.363281 L 15.685547 37.181641 C 16.224197 37.598161 16.786995 37.97163 17.369141 38.298828 C 17.41544 33.633463 20.48998 30.918482 25.494141 32.421875 C 26.752154 32.79982 28.470438 32.881775 29.552734 33.523438 C 30.503278 29.864387 32.878523 25.882812 36.423828 25.882812 L 42.007812 23.222656 L 35.998047 21.404297 C 35.48667 19.165875 34.545646 17.067895 33.244141 15.263672 L 35.486328 9.0195312 L 30.322266 12.201172 C 28.385093 10.678712 26.155852 9.7353058 23.849609 9.4648438 L 21.654297 4 z M 36.980469 27.007812 C 33.435165 27.007812 31.061871 30.989386 30.111328 34.648438 C 29.029032 34.006776 27.310747 33.922866 26.052734 33.544922 C 20.069154 31.747282 16.827164 35.972436 18.246094 42.363281 C 18.466603 43.356495 18.500261 44.318144 18.783203 45.230469 C 16.638892 46.589774 12.634766 49.268696 12.634766 52.242188 C 12.634766 56.623055 18.270327 60.000011 22.148438 60 L 51.8125 60 C 55.690596 60 60 56.623056 60 52.242188 C 60 49.268695 57.323994 46.589774 55.179688 45.230469 C 55.462643 44.318163 55.523187 43.368075 55.714844 42.363281 C 56.81099 36.616546 53.716511 32.434064 47.910156 33.544922 C 46.630035 33.789828 45.429958 34.006776 44.347656 34.648438 C 43.397113 30.989386 40.525774 27.007813 36.980469 27.007812 z'
  ]
}