// Auto-generated file by generate-icons.js


export default {
  prefix: 'waypoint',
  iconName: 'summit',
  icon: [
    14,
    14,
    [],
    "",
    'M 6.3527886,8.4370129 6.0049604,6.3755713 4.6510066,7.1227441 6.3527886,3.9448812 8.0533993,7.1227441 6.6994456,6.3755713 Z M 10.855851,9.134724 10.059821,8.6966476 9.8567266,9.906727 9.6524676,8.6966476 8.8564341,9.134724 9.8567266,7.2675995 Z M 13,12 9.8567266,6.1264671 9.2077615,7.3365468 6.3527886,2 1,12 Z M 13,12'
  ]
}