// Auto-generated file by generate-icons.js


export default {
  prefix: 'waypoint',
  iconName: 'lake',
  icon: [
    14,
    14,
    [],
    "",
    'M3.29 7.26c.21 0 .42.1.58.28a.8.8 0 0 1 .59-.26c.21 0 .42.1.58.27a.8.8 0 0 1 .59-.26c.2 0 .41.1.58.27a.8.8 0 0 1 .58-.26c.11 0 .2.12.2.26s-.1.26-.2.26c-.17 0-.33.1-.44.27-.03.06-.09.09-.15.09-.05 0-.1-.04-.14-.1-.11-.17-.27-.27-.44-.27-.17 0-.33.1-.44.27-.03.05-.09.09-.14.09-.06 0-.11-.04-.15-.1-.1-.17-.27-.27-.43-.28-.17 0-.33.1-.44.27-.04.06-.1.1-.15.1-.06 0-.11-.04-.15-.1-.1-.18-.26-.28-.43-.28-.1 0-.2-.12-.2-.26s.1-.26.2-.26m2.2-2.77c.22 0 .42.1.59.28a.8.8 0 0 1 .58-.27c.21 0 .42.1.58.28a.8.8 0 0 1 .59-.27c.21 0 .42.1.58.28A.8.8 0 0 1 9 4.53c.1 0 .19.11.19.26 0 .14-.09.26-.2.25-.17 0-.32.1-.43.27-.04.06-.1.1-.15.1-.06 0-.11-.04-.15-.1-.1-.17-.27-.28-.43-.28-.17 0-.33.1-.44.27-.04.06-.1.1-.15.1-.06 0-.11-.04-.15-.1-.1-.18-.26-.28-.43-.28-.17 0-.33.1-.44.27-.04.06-.1.1-.15.1-.06 0-.1-.04-.15-.1C5.82 5.1 5.66 5 5.5 5c-.1 0-.2-.12-.2-.26.01-.15.1-.26.2-.26M9.82 9.2a.8.8 0 0 1 .59-.26c.1 0 .19.12.19.26s-.09.26-.2.26c-.16 0-.32.1-.43.27-.04.05-.1.09-.15.09-.06 0-.11-.04-.15-.1-.1-.17-.27-.27-.43-.28-.17 0-.33.1-.44.27-.04.06-.1.1-.15.1-.06 0-.11-.04-.15-.1-.1-.17-.26-.28-.43-.28-.17 0-.33.1-.44.27-.04.06-.1.1-.15.1-.05 0-.1-.04-.14-.1-.11-.18-.27-.28-.44-.28-.1 0-.2-.12-.19-.26 0-.14.09-.26.2-.26.2 0 .41.1.58.28a.8.8 0 0 1 .58-.27c.21 0 .42.1.58.28a.8.8 0 0 1 .59-.26c.21 0 .42.1.58.27M1 8.18c0 .27.19.53.53.77 0 0 6.13 4.6 11.06.02.26-.2.4-.43.41-.67 0-.61-.95-1.16-2.42-1.52-.43-.3-1.5-.4-.92-.95.26-.25.45-.44.58-.59a.9.9 0 0 0 .32-.4c.04-.07.04-.1.04-.1v-.08c.02-.79-1.6-1.53-3.63-1.64-2.02-.12-3.67.42-3.69 1.21 0 .34.28.66.76.93l.21.11c.19.12.43.32.4.6-.03.57-.64.73-.8.76H3.8C2.13 6.96 1 7.53 1 8.19'
  ]
}