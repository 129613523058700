// Auto-generated file by generate-icons.js


export default {
  prefix: 'miscs',
  iconName: 'drill',
  icon: [
    14,
    14,
    [],
    "",
    'M2 1L0 3v1l2 2v4h3V7 6h4V1zm7 0v5l2-2h3V3h-3zM3 3L2 4C1 4 1 4 2 4zm2 0L4 4H3l1-1zm-3 8H1v2l1 1h5v-3z'
  ]
}