// Auto-generated file by generate-icons.js


export default {
  prefix: 'waypoint',
  iconName: 'webcam',
  icon: [
    14,
    14,
    [],
    "",
    'M 6.9997967,1 C 4.2383555,1 2,3.3003015 2,6.1371362 2,8.1476256 3.1253232,9.8886014 4.7636333,10.731984 4.3054662,11.20692 3.9885378,11.827048 3.8869533,12.520284 3.8693315,12.640615 3.9035279,12.762606 3.9807528,12.855251 4.0579778,12.94683 4.1706691,13 4.2893571,13 H 9.7110488 C 9.8292186,13 9.9417281,12.946945 10.018435,12.854833 10.09566,12.762721 10.130262,12.640615 10.112641,12.520284 10.011056,11.827048 9.6950518,11.20692 9.2363664,10.731984 10.875195,9.8886014 12,8.1476256 12,6.1371362 12,3.3003015 9.7612383,1 6.9997967,1 Z M 6.9997967,2.6973614 C 8.8459411,2.6973614 10.348155,4.2405887 10.348155,6.1371362 10.348155,8.0331512 8.8459411,9.5760767 6.9997967,9.5760767 5.153653,9.5760767 3.6522516,8.0331512 3.6522516,6.1371362 3.6522516,4.2405887 5.153653,2.6973614 6.9997967,2.6973614 Z M 6.9997967,4.3129628 C 6.0207498,4.3129628 5.2241036,5.1313591 5.2241036,6.1371362 5.2241036,7.142381 6.0207498,7.9604753 6.9997967,7.9604753 7.9788439,7.9604753 8.7754902,7.142381 8.7754902,6.1371362 8.7754902,5.1313591 7.9788439,4.3129628 6.9997967,4.3129628 Z'
  ]
}