// Auto-generated file by generate-icons.js


export default {
  prefix: 'waypoint',
  iconName: 'waterpoint',
  icon: [
    14,
    14,
    [],
    "",
    'M 6.829529,1 C 6.5998654,1 6.4863224,1.1338462 6.4863224,1.2984057 H 4.9423459 C 4.6595553,1.2984057 4.4279891,1.4979066 4.4279891,1.7452264 4.4279891,1.9925461 4.6595553,2.1916539 4.9423459,2.1916539 H 6.4863224 V 2.7888589 H 6.3129082 C 6.0301175,2.7888589 5.7994567,2.9882729 5.7994567,3.2360737 5.7994567,3.4193981 5.9268353,3.5745453 6.1077992,3.6443147 5.6822297,3.7679745 5.3090518,3.9895625 5.0229407,4.2797059 H 2.3705617 V 3.3840955 C 2.3705617,3.0549774 2.0643891,2.7888589 1.6864125,2.7888589 1.3062224,2.7888589 1,3.0549774 1,3.3840955 V 7.8558494 C 1,8.186411 1.3062224,8.4526606 1.6864125,8.4526606 2.0643891,8.4526606 2.3705617,8.186411 2.3705617,7.8558494 V 6.6638015 H 4.7114289 C 5.1231632,7.3432092 5.9415626,7.8062464 6.8856732,7.8062464 8.086012,7.8062464 9.0839115,7.0584128 9.2980798,6.0681714 H 10.257971 C 10.445576,6.0681714 10.599367,6.202368 10.599367,6.3650022 V 6.9622072 C 10.411761,6.9622072 10.257971,7.0960538 10.257971,7.2606129 V 7.8558494 C 10.257971,8.0204086 10.411761,8.1538612 10.599367,8.1538612 H 12.656794 C 12.846059,8.1538612 13,8.0204086 13,7.8558494 V 7.2606129 C 13,7.0960538 12.846059,6.9622072 12.656794,6.9622072 V 4.8749425 C 12.656794,4.711827 12.502852,4.5773243 12.313587,4.5773243 H 8.993812 C 8.6827982,4.123584 8.1851592,3.7786918 7.592461,3.6242371 7.749629,3.5486941 7.8568841,3.4030386 7.8568847,3.2360737 7.8568847,2.9882729 7.6263245,2.7888589 7.3429805,2.7888589 H 7.1704716 V 2.1916539 H 8.7144475 C 8.9988984,2.1916539 9.227899,1.9925461 9.227899,1.7452264 9.227899,1.4979066 8.9988984,1.2984057 8.7144475,1.2984057 H 7.1704716 C 7.1704716,1.1338462 7.056979,1 6.829529,1 Z M 11.563333,9.2718984 C 11.563333,9.2718984 10.128929,11.062801 10.128929,11.75087 10.128929,12.440862 10.772113,13 11.565144,13 12.357068,13 13,12.440862 13,11.75087 13,11.062801 11.563333,9.2718982 11.563333,9.2718984 Z'
  ]
}