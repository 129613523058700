// Auto-generated file by generate-icons.js


export default {
  prefix: 'waypoint',
  iconName: 'waterfall',
  icon: [
    14,
    14,
    [],
    "",
    'M4.86 10.98a2 2 0 0 0 1 .25 2 2 0 0 0 1.02-.22c.3-.14.63-.3 1.26-.28.64 0 .97.17 1.26.31a2 2 0 0 0 1 .26c.2 0 .36-.01.49-.04v-.01c.15-.42.04-.72-.07-.9a.87.87 0 0 0-.69-.43.85.85 0 0 0-.6.23.3.3 0 0 1-.23.08.31.31 0 0 1-.21-.12c-.2-.27-.49-.42-.8-.42a.97.97 0 0 0-.5.13.3.3 0 0 1-.37-.05c-.26-.26-.6-.41-.96-.42-.35 0-.7.13-.96.38a.3.3 0 0 1-.36.03c-.16-.1-.34-.15-.53-.15-.56-.01-1.03.45-1.1 1.05h.09c.63.01.96.17 1.26.32m3.03-7.24L8.08 9a1.53 1.53 0 0 1 .82.12L8.5 3.75l-.6-.01zm-.64-.01h-.5l-.27 4.92c.34 0 .67.1.96.28l-.19-5.2zM5 8.96c.1.02.18.05.26.1.18-.14.38-.25.58-.31l.28-5.04h-.6L5 8.95zm8 2.19c0 .2-.15.35-.32.34-.5 0-.74.1-1.01.23-.3.14-.64.29-1.27.28-.63 0-.96-.17-1.26-.32-.27-.13-.5-.24-1-.25-.5 0-.74.1-1.02.22-.3.14-.63.3-1.26.28-.64 0-.97-.17-1.26-.31-.27-.13-.5-.25-1-.26-.51 0-.75.1-1.02.23-.3.14-.63.29-1.27.28-.17 0-.31-.16-.31-.36l.1-7.5c0-.2.15-.35.32-.35l3.46.05.04-.4c.02-.18.16-.31.32-.31l3.54.05c.17 0 .3.14.32.32l.03.4 3.42.05c.17 0 .31.16.31.36l.14 6.97'
  ]
}