// Auto-generated file by generate-icons.js


export default {
  prefix: 'quality',
  iconName: 'draft',
  icon: [
    16,
    16,
    [],
    "",
    'M 8,1 C 4.1399295,1 0.99999994,4.1399297 1,8 1,11.86007 4.1399295,15 8,15 11.860071,15 15,11.86007 15,8 15,4.1399295 11.860071,0.9999997 8,1 Z M 8,2 V 8 H 14 C 14,11.319574 11.319575,14 8,14 4.6804256,14 2,11.319574 2,8 1.9999999,4.6804259 4.6804256,2 8,2 Z'
  ]
}