// Auto-generated file by generate-icons.js


export default {
  prefix: 'waypoint',
  iconName: 'locality',
  icon: [
    14,
    14,
    [],
    "",
    'M 10,6 C 10.152538,6 10.3854,5.6357408 10.3854,5.5 10.3854,5.3642591 10.152538,5 10,5 H 3 C 2.8480378,5 2.6145998,5.3642591 2.6145998,5.5 2.6145998,5.6357408 2.8480378,6 3,6 Z M 3,3 C 2.8480473,2.9983019 2.6145998,3.3647714 2.6145998,3.5 2.6145998,3.6357408 2.8480378,4 3,4 H 10 C 10.152538,4 10.3854,3.6357408 10.3854,3.5 10.3854,3.3647714 10.152538,3 10,3 M 1,7 V 2 H 6 V 1 H 7 V 2 H 12 V 7 H 7 V 12 H 9.5 V 13 H 3.5 V 12 H 6 V 7 Z'
  ]
}