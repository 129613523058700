<template>
  <div id="camptocamp_htd" class="ams-ad is-flex is-justify-content-center" />
</template>

<script>
export default {};

var s = document.createElement('script');
s.src = 'https://static.admysports.com/hb/site/camptocamp/hb_camptocamp.js';
document.getElementsByTagName('head')[0].appendChild(s);
</script>
