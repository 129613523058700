// Auto-generated file by generate-icons.js


export default {
  prefix: 'waypoint',
  iconName: 'shelter',
  icon: [
    14,
    14,
    [],
    "",
    'M 7.1996379,1.085732 C 9.1024937,2.9485818 11.005307,4.8114754 12.908122,6.6743665 13.015784,6.9052982 13.023675,6.9921082 12.710194,7 H 11 V 12.69154 C 11,12.861193 10.933751,13 10.773,13 H 9.8653774 C 9.704103,13 9.5768639,12.861193 9.5768639,12.69154 V 8 H 4.426033 V 12.69154 C 4.426033,12.861193 4.2946048,13 4.1328069,13 H 3.225 C 3.0642493,13 3.007867,12.861011 3,12.69154 V 7 H 1.2895608 C 0.81529906,6.9865051 1.0671659,6.8019811 1.1078653,6.6611468 3.0078746,4.802421 4.9057645,2.9415328 6.8037826,1.0807746 6.914266,0.97226275 7.0881072,0.97226275 7.1996379,1.085732'
  ]
}