var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card"},[_c('div',{staticClass:"card-image img-container",style:(_vm.dataUrl ? 'background-image: url(' + _vm.dataUrl + ')' : '')},[_c('div',{staticClass:"action-buttons"},[_c('delete-button',{attrs:{"title":_vm.$gettext('Delete image')},on:{"click":function($event){return _vm.$emit('delete-image')}}}),_vm._v(" "),(_vm.canRotate)?_c('rotate-button',{attrs:{"disabled":_vm.rotateClicked || _vm.disabled,"title":_vm.$gettext('Rotate image right')},on:{"click":function($event){return _vm.applyRotation(90)}}}):_vm._e(),_vm._v(" "),(_vm.canRotate)?_c('rotate-button',{attrs:{"direction":"left","disabled":_vm.rotateClicked || _vm.disabled,"title":_vm.$gettext('Rotate image left')},on:{"click":function($event){return _vm.applyRotation(-90)}}}):_vm._e()],1),_vm._v(" "),(_vm.isSaving || _vm.isFailed)?_c('progress',{staticClass:"progress is-large",class:{
        'is-success': _vm.isSuccess,
        'is-warning': _vm.isSaving,
        'is-danger': _vm.isFailed,
      },attrs:{"max":"100"},domProps:{"value":_vm.percentCompleted}},[_vm._v("\n      "+_vm._s(_vm.percentCompleted)+"%\n    ")]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"card-content"},[(!_vm.isFailed)?_c('div',[(_vm.categoriesEdition)?_c('div',{staticClass:"columns is-mobile is-multiline is-gapless category-select"},_vm._l((_vm.imageCategories),function(item){return _c('div',{key:item,staticClass:"column is-4"},[_c('label',{staticClass:"checkbox"},[_c('input',{attrs:{"type":"checkbox"},domProps:{"checked":_vm.document.categories.includes(item)},on:{"input":function($event){return _vm.toggleCategory(item)}}}),_vm._v("\n            "+_vm._s(_vm._f("uppercaseFirstLetter")(_vm.$gettext(item, 'image_categories')))+"\n          ")])])}),0):_c('div',[_c('div',{staticClass:"field title-input"},[_c('div',{staticClass:"control"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.document.locales[0].title),expression:"document.locales[0].title"}],attrs:{"type":"text","placeholder":_vm.$gettext('title'),"maxlength":"150"},domProps:{"value":(_vm.document.locales[0].title)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.document.locales[0], "title", $event.target.value)}}})])]),_vm._v(" "),_c('div',{staticClass:"columns is-mobile is-gapless licence-select"},_vm._l((Array.from(_vm.licences)),function([licence, label]){return _c('div',{key:licence,staticClass:"column has-text-centered",class:{
              'has-background-success': _vm.document.image_type == licence,
              'is-4': _vm.licences.size === 3,
              'is-6': _vm.licences.size === 2,
            },on:{"click":function($event){_vm.document.image_type = licence}}},[_c('label',[_vm._v(_vm._s(_vm.licences.length)+" "+_vm._s(_vm._f("uppercaseFirstLetter")(label)))])])}),0)])]):_c('div',{staticClass:"buttons is-centered buttons-if-failed"},[_c('button',{directives:[{name:"translate",rawName:"v-translate"}],staticClass:"button is-primary",on:{"click":function($event){return _vm.$emit('retry-upload')}}},[_vm._v("Retry")]),_vm._v(" "),_c('button',{directives:[{name:"translate",rawName:"v-translate"}],staticClass:"button is-danger",on:{"click":function($event){return _vm.$emit('delete-image')}}},[_vm._v("Cancel")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }