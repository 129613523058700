// Auto-generated file by generate-icons.js


export default {
  prefix: 'waypoint',
  iconName: 'cave',
  icon: [
    14,
    14,
    [],
    "",
    'M 13,11 10,7 9,8 6,4 1,11 H 4 C 4.5788425,9.08642 5.5126506,8 6.5,8 7.4870505,8 8.4211575,9.086103 9,10.999684'
  ]
}