// Auto-generated file by generate-icons.js


export default {
  prefix: 'activity',
  iconName: 'other',
  icon: [
    64,
    64,
    [],
    "",
    'M 32,4 C 47.46,4 60,16.54 60,32 C 60,47.46 47.46,60 32,60 C 16.54,60 4,47.46 4,32 C 4,16.54 16.54,4 32,4 Z M 21,32 C 18.24,32 16,34.24 16,37 C 16,39.76 18.24,42 21,42 C 23.76,42 26,39.76 26,37 C 26,34.24 23.76,32 21,32 Z M 35,32 C 32.24,32 30,34.24 30,37 C 30,39.76 32.24,42 35,42 C 37.76,42 40,39.76 40,37 C 40,34.24 37.76,32 35,32 Z M 49,32 C 46.24,32 44,34.24 44,37 C 44,39.76 46.24,42 49,42 C 51.76,42 54,39.76 54,37 C 54,34.24 51.76,32 49,32 Z'
  ]
}