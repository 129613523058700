// Auto-generated file by generate-icons.js


export default {
  prefix: 'waypoint',
  iconName: 'camp_site',
  icon: [
    14,
    14,
    [],
    "",
    'M 7,7 9,10 H 5 Z M 2,10 H 1.2443223 C 1.1095238,10 1,10.349025 1,10.5 1,10.651385 1.1095238,11 1.2443223,11 H 12.755678 C 12.890476,11 13,10.651385 13,10.5 13,10.349025 12.878308,9.944032 12.755678,10 H 12 L 7,3 Z'
  ]
}