var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box"},[_c('h4',{staticClass:"title is-3"},[_c('router-link',{attrs:{"to":{
        name: 'outings',
        query: {
          qa: 'draft,great',
          offset: 0,
          limit: 100,
        },
      }}},[_c('icon-outing'),_vm._v("\n      "+_vm._s(_vm._f("uppercaseFirstLetter")(_vm.$gettext('outings')))+"\n    ")],1)],1),_vm._v(" "),_c('loading-notification',{attrs:{"promise":_vm.outingsPromise}}),_vm._v(" "),(_vm.outingsByDate)?_c('div',_vm._l((_vm.outingsByDate),function(sortedOutings,date){return _c('div',{key:date},[_c('p',{staticClass:"outing-date-header is-4 is-italic has-text-weight-bold"},[_c('router-link',{attrs:{"to":{ name: 'outings', query: { date: `${date},${date}` } }}},[_vm._v("\n          "+_vm._s(_vm._f("uppercaseFirstLetter")(_vm.$dateUtils.toLocalizedString(date, '@1')))+"\n        ")])],1),_vm._v(" "),_c('div',{staticClass:"dashboard-list"},_vm._l((sortedOutings),function(outing){return _c('dashboard-outing-link',{key:outing.document_id,attrs:{"outing":outing}})}),1)])}),0):_vm._e(),_vm._v(" "),_c('hr'),_vm._v(" "),_c('h6',{staticClass:"title is-6 has-text-centered"},[_c('router-link',{attrs:{"to":{
        name: 'outings',
        query: {
          qa: 'draft,great',
          bbox: '-431698,3115462,1931123,8442818',
          offset: 0,
          limit: 100,
        },
      }}},[_c('span',{directives:[{name:"translate",rawName:"v-translate"}]},[_vm._v("See more")])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }