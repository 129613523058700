// Auto-generated file by generate-icons.js


export default {
  prefix: 'waypoint',
  iconName: 'virtual',
  icon: [
    14,
    14,
    [],
    "",
    'M 6.9823721,1.6456825 C 3.879414,1.6456825 1.3617968,4.0794238 1.3617968,7.0790048 1.3617968,10.078586 3.879414,12.512327 6.9823721,12.512327 10.08533,12.512327 12.602948,10.078586 12.602948,7.0790048 12.602948,4.0794238 10.08533,1.6456825 6.9823721,1.6456825 Z M 6.9823721,1.8467857 C 9.9729004,1.8467857 12.394913,4.188108 12.394913,7.0790048 12.394913,9.969902 9.9729004,12.311224 6.9823721,12.311224 3.991844,12.311224 1.5698308,9.969902 1.5698308,7.0790048 1.5698308,4.188108 3.991844,1.8467857 6.9823721,1.8467857 Z M 6.9573475,0.5 6.285552,5.693961 4.6958072,4.5014906 6.0440598,6.2897454 C 0.5,6.9896315 6.0440598,6.2897454 0.5,6.9896315 5.803967,7.7710923 0.5,6.9896315 5.803967,7.7710923 L 4.5792571,9.2871429 6.2613098,8.0487045 6.9787933,13.5 7.7312359,7.9414481 9.5144544,9.2614579 8.3456886,7.7548711 13.5,7.072104 8.1237729,6.3514863 9.6314665,4.4293828 7.7410219,5.8381743 Z'
  ]
}