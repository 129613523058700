// Auto-generated file by generate-icons.js


export default {
  prefix: 'miscs',
  iconName: 'paragliding',
  icon: [
    14,
    14,
    [],
    "",
    'M7.1 0C5 0 2.1.5.8 1c-1.3.4-.7.6-.4 1.3.3.7 1 1.2 1.3 1.8l.8.3 2 2.4.2 1.7c.3.5.7.5 1.2.7V11l-.5.8v1.7c0 .3.3.5.5.5.3 0 .6-.2.6-.5V12l.6-.4.5.4v1.5c0 .3.3.5.6.5.2 0 .5-.2.5-.5v-1.7c.1-.4-.3-.5-.4-.7V9.2c.5 0 1-.4 1.2-.7l.2-1.7 1.9-2.4c.4 0 .5 0 .8-.3l1.3-1.8c.1-.3.8-.9-.4-1.4-1.2-.4-4-.9-6.2-.9zm0 3.7c.5 0 2 0 3 .3l-.7 2.5-1 1.7-.8.2H6.3l-.6-.2a4 3.5 0 00-.8-1.7L4 4zm-3.3.4l.7 2.3-1.7-2zm6.6 0l1 .2-1.7 2.1zM7.1 6.5c-.5 0-1 .3-1 .8s.4.9 1 .9c.7 0 1-.3 1-.8 0-.6-.5-.9-1-.9z'
  ]
}