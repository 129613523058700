// Auto-generated file by generate-icons.js


export default {
  prefix: 'waypoint',
  iconName: 'pit',
  icon: [
    14,
    14,
    [],
    "",
    'M 13,13 H 1 V 1 H 4.3224859 L 5.6558192,2.3898867 5.3715254,4.4346505 6.5846328,5.0359215 6.3116384,6.8264714 6.759548,7.4675325 6.3988701,11.222713 7.4370621,8.6706272 7.2187571,7 8.6614689,4.4346505 8.2022599,2.8043658 8.9127684,1 H 13 Z'
  ]
}