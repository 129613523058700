// Auto-generated file by generate-icons.js


export default {
  prefix: 'waypoint',
  iconName: 'canyon',
  icon: [
    14,
    14,
    [],
    "",
    'M 13,13 V 6.9999881 C 13,6.9784113 12.997277,6.9568345 12.991053,6.9360875 L 12.433221,5.155171 12.060555,2.1734219 C 12.048885,2.0738369 11.969139,1.9995629 11.874611,1.9995629 H 11.3125 C 11.246758,1.9995629 11.185296,2.0369069 11.151452,2.0974879 L 10.589341,3.0974899 C 10.573781,3.1236319 10.565612,3.1539219 10.562889,3.1850419 L 10.381613,5.898326 9.5082988,6.6431409 C 9.4631743,6.6817302 9.4375,6.7389917 9.4375,6.7995727 V 10.676759 L 8.0806535,11.400412 H 6.5534232 L 6.060166,10.346468 5.875389,7.1871254 C 5.871499,7.1269593 5.8423237,7.0721874 5.7964212,7.0369176 L 4.562111,6.0974966 V 4.4003976 C 4.562111,4.3207294 4.5193205,4.2497749 4.4516338,4.2174097 L 2.875,3.4705199 V 1.7991469 C 2.875,1.7290229 2.8403786,1.6647069 2.784362,1.6286069 L 1.846473,1.0277759 C 1.7834544,0.98877187 1.7068205,0.99084687 1.6461359,1.0339999 L 1.0828579,1.4331709 C 1.0311203,1.4705159 1,1.5327569 1,1.6003919 V 13 Z'
  ]
}