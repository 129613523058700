// Auto-generated file by generate-icons.js


export default {
  prefix: 'waypoint',
  iconName: 'bergschrund',
  icon: [
    14,
    14,
    [],
    "",
    'm 29.218319,12.874222 -27.7015693,36.418873 29.7974453,0 32.310067,0 L 47.352903,27.901408 44.00274,32.309625 29.218319,12.874222 Z m -0.532061,3.530215 15.405495,19.182522 3.461432,-4.477 12.854434,16.368464 -26.287459,0 -2.275928,-3.463454 -6.148036,1.438385 -3.137745,-1.56786 -3.40074,1.830856 -2.093852,-1.830856 0.131498,-3.663736 -7.9445017,2.097898 19.4354027,-25.915219 z m 0.540153,2.72909 -7.533823,9.90281 5.93764,-2.306273 1.596183,6.651775 1.592138,-6.651775 5.935617,2.306273 -7.527755,-9.90281 z m 0.04451,1.689243 4.867449,6.228958 -4.033954,-2.075645 -0.898232,4.752135 -0.835518,-4.687397 -3.451317,1.490985 4.351572,-5.709036 z m 18.618093,13.192282 -4.309089,5.662506 3.396694,-1.319025 0.912395,3.807372 0.912393,-3.807372 3.394672,1.319025 -4.307065,-5.662506 z m -0.004,1.01557 c 0,0.0694 2.77562,3.556515 2.77562,3.556515 l -2.275927,-0.912395 -0.509807,2.079692 -0.327735,-2.073622 -1.982584,0.653444 c 0,0 2.320433,-3.373758 2.320433,-3.303634 z m -31.915573,6.667959 -0.277157,2.913186 3.236873,2.265812 3.700152,-1.988654 3.143813,1.804557 5.132467,-1.294749 0.370218,2.172751 -25.8949888,0 3.5605611,-4.161406 7.0280617,-1.711497 z'
  ]
}