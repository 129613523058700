// Auto-generated file by generate-icons.js


export default {
  prefix: 'activity',
  iconName: 'skitouring',
  icon: [
    64,
    64,
    [],
    "",
    'M32 4a28 28 0 1 1 0 56 28 28 0 0 1 0-56zm-1.3 6.3s-.6.4-.6.8 1 .8 1 .8l1.4 2c-.6 0-.8 1.2-1 1.7-.2.4-.8.5-1.8 1-.9.6-1 2.4-1 2.4s-1.8.2-2 1.2c-.3.9-3.3 3.5-3.3 3.5s-.9 1.8-1 2.5c0 .7-1.4 2.4-1.4 2.4l-.6.1L9 22.3s0-.2-.3-.4-1.2 0-1 .6c.1.6 1 .4 1 .4l11.6 6.8s-.6.5 1 1.5c3 2 2.1-1.9 2.2-2.2 0-.4 1-1.2 1.4-1.6.4-.4.2-1.4.2-1.4.8-.2 1.6-1.9 2-1.6.5.3 0 1 0 1l.6.5s-.3.7-.5 2.2c-.3 4.1 5.6 5.5 5.6 5.5v2.7h-1l-1-.5h-2.1l18 14.8h2.8l1.3-2.4-9.2-5.7c0-1-1.4-2.4-1.4-2.4s-1-1-1-1.5c0-2.7-3.1-8.4-3.6-9.2-.4-.8-.3-3.9-.6-4-.4-.2-.4-1.1-.4-1.1s.7.1.6-.4c-.2-.6.1-1.5.1-1.5 1.5-1 5.7 1.6 5.7 1.6s3.4 0-.6-2.6-3.4-4-3.4-4 .2-1.8-.8-2.8c-1-1-2.7-.9-2.7-.9l-2-2.2c.6-1-.8-1.2-.8-1.2z'
  ]
}