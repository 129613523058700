// Auto-generated file by generate-icons.js


export default {
  prefix: 'waypoint',
  iconName: 'confluence',
  icon: [
    14,
    14,
    [],
    "",
    'M 5.8426997,1.9999995 3.6494344,4.5670335 3.3986389,4.2845666 2.8844831,5.5481049 2.1075216,5.68471 1.9588494,6.7526437 1.5388078,6.611238 1.2326153,7.8827881 C 1.2326153,7.8827881 -0.42172916,10.238535 5.0932617,10.854963 10.608282,11.471148 5.4913585,9.9245807 5.4913585,9.9245807 5.4913585,9.9245807 1.1297396,8.7315194 6.1659527,7.9646598 11.201942,7.1977672 8.270486,7.0705945 8.270486,7.0705945 L 8.080845,6.6738655 C 8.5132722,6.7146079 11.588023,7.1497721 10.28583,7.5408213 8.9836357,7.9318705 8.3724792,8.1127394 8.3724792,8.1127394 8.3724792,8.1127394 10.449265,8.8609124 11.333718,8.3078854 11.333718,8.3078854 12.274548,7.7323274 12.704524,7.8215557 L 13,6.7947016 12.138437,7.0016536 12.134225,5.6241399 11.628542,6.1897056 10.776832,3.6438422 10.037245,5.5122411 9.6560013,4.2662989 8.6207765,5.4350262 8.2292727,4.742458 7.8145302,5.341571 7.1474519,3.3150225 6.770293,3.9194559 5.842701,1.9999995 Z M 5.7366985,2.7969356 6.4341469,4.4165502 7.019172,4.1396291 7.5292319,6.6781028 6.5054354,6.1839679 6.1760103,4.5852499 5.7140457,5.4960645 5.4794777,4.7640645 4.9523473,6.0565898 5.1672722,4.3605106 3.9918035,5.8995687 3.7029984,5.5402997 2.9976653,6.4166648 3.4613393,5.0230617 3.6038417,5.2589996 5.7366996,2.7969358 Z M 12.567015,8.4505154 C 12.377023,8.4203604 11.917919,8.4174714 11.287415,8.8775844 10.747609,9.2712447 8.7566583,8.7727924 7.7187905,8.3284424 7.7187905,8.3284424 3.5810054,8.5856434 5.7736099,9.1631197 7.96599,9.7405627 13.951435,11.183824 12.663764,8.4736094 12.663764,8.4736094 12.630346,8.4605664 12.567015,8.4505134 Z'
  ]
}