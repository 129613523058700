// Auto-generated file by generate-icons.js


export default {
  prefix: 'waypoint',
  iconName: 'glacier',
  icon: [
    14,
    14,
    [],
    "",
    'M 6.5514006,3.999698 1,11 H 13 L 10,6.888497 8.9914007,8 Z M 6.5,4.678302 9.0085993,8.5 10,7.5050641 12.378501,10.651203 1.7465798,10.651519 Z M 6.4535864,5.203382 4.9979512,7.1065746 6.1451824,6.66314 6.4535864,7.9418521 6.7612085,6.66314 7.7502124,7.2012764 Z M 9.959739,8.063393 9.1271658,9.152198 9.783452,8.898671 9.959739,9.630132 10.136026,8.898671 10.791922,9.152198 Z'
  ]
}