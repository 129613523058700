// Auto-generated file by generate-icons.js


export default {
  prefix: 'waypoint',
  iconName: 'gite',
  icon: [
    14,
    14,
    [],
    "",
    'M 6.9993807,1 C 6.8651975,1 6.7310721,1.048954 6.6339098,1.146754 L 1.1502852,6.664787 C 0.94990495,6.8681445 0.94990495,7.1975608 1.1502852,7.4009182 1.3523169,7.6037215 1.6795858,7.6119116 1.8816175,7.4080001 L 2.2875004,7 H 11.723985 L 12.117535,7.4080001 C 12.218275,7.5099558 12.350336,7.5575084 12.483005,7.5575084 12.614574,7.5575084 12.747185,7.5048412 12.848476,7.4028855 13.050508,7.2000821 13.050508,6.8681445 12.848476,6.664787 L 7.3648516,1.146754 C 7.2676892,1.048954 7.1335639,1 6.9993807,1 Z M 6.9993807,2.250361 8.2544891,3.513705 6.9993807,4.7758689 5.7442721,3.513705 Z M 5.0133305,4.5 6.4716954,6 H 3.5549653 Z M 8.9858218,4.5 10.443014,6 H 7.5274563 Z M 3,8 V 12.431869 C 3,12.747156 3.1878693,13 3.5,13 H 10.5 C 10.812131,13 10.981207,12.746595 11,12.431869 V 8 Z M 5.4300062,9.2032133 H 6.2402959 C 6.4423276,9.2032133 6.6057668,9.3693708 6.6057668,9.5738362 V 11.427738 C 6.6057668,11.631096 6.4423276,11.796787 6.2402959,11.796787 H 5.4300062 C 5.2279746,11.796787 5.0645354,11.632204 5.0645354,11.427738 V 9.5738362 C 5.0645354,9.3693708 5.2279746,9.2032133 5.4300062,9.2032133 Z M 7.7526022,9.2032133 H 8.5765728 C 8.7786045,9.2032133 8.9436071,9.3692987 8.9436071,9.572656 V 11.427738 C 8.9436071,11.631096 8.7786045,11.796787 8.5765728,11.796787 H 7.7526022 C 7.5505705,11.796787 7.3859587,11.632204 7.3859587,11.427738 V 9.572656 C 7.3859587,9.3692987 7.5505705,9.2032133 7.7526022,9.2032133 Z'
  ]
}