var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box no-print"},[(_vm.document.areas && _vm.document.areas.length)?_c('div',{staticClass:"has-text-centered"},[_c('icon-area'),_vm._v(" "),_c('span',[_vm._v(" ")]),_vm._v(" "),_vm._l((_vm.document.areas),function(area){return _c('span',{key:area.document_id,staticClass:"area-link"},[_c('document-link',{attrs:{"document":area}}),_vm._v(" \n    ")],1)})],2):_vm._e(),_vm._v(" "),_c('div',{attrs:{"id":"fullscreen-map-container"}},[_c('div',{staticClass:"map-container",class:{
        'with-elevation-profile': _vm.showElevationProfile && !_vm.elevationProfileHidden,
        pinned: _vm.pinnedMode,
        'pinned-to-top': _vm.pinnedSide === 'top',
        'pinned-to-right': _vm.pinnedSide === 'right',
        'fill-parent': !_vm.pinnedMode,
      }},[_c('map-view',{ref:"mapView",attrs:{"documents":new Array(_vm.document),"show-protection-areas":['r', 'w'].includes(_vm.document.type),"biodiv-sports-activities":_vm.document.activities,"full-screen-element-id":!_vm.$screen.isMobile && _vm.showElevationProfile && _vm.elevationProfileHasData ? 'fullscreen-map-container' : null,"show-pin-to-top-button":true},on:{"has-protection-area":function($event){return _vm.$emit('has-protection-area')},"pin-to-top-clicked":function($event){return _vm.togglePinToSide(true)}}})],1),_vm._v(" "),(_vm.showElevationProfile)?_c('elevation-profile',{attrs:{"document":_vm.document},on:{"has-data":function($event){_vm.elevationProfileHasData = true}}}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"buttons is-centered"},[(_vm.showDownloadTraceButtons)?_c('button',{staticClass:"button is-primary is-small",on:{"click":_vm.downloadGpx}},[_vm._v("GPX")]):_vm._e(),_vm._v(" "),(_vm.showDownloadTraceButtons)?_c('button',{staticClass:"button is-primary is-small",on:{"click":_vm.downloadKml}},[_vm._v("KML")]):_vm._e(),_vm._v(" "),(_vm.hasMapLinks)?_c('button',{staticClass:"button is-small",class:{ 'is-success': _vm.mapLinksAreVisible },on:{"click":function($event){_vm.mapLinksAreVisible = !_vm.mapLinksAreVisible}}},[_c('icon-map')],1):_vm._e()]),_vm._v(" "),(_vm.mapLinksAreVisible)?_c('map-links',{attrs:{"document":_vm.document}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }