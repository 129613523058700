// Auto-generated file by generate-icons.js


export default {
  prefix: 'waypoint',
  iconName: 'access',
  icon: [
    14,
    14,
    [],
    "",
    'M 12,1.387565 C 12,1.387565 11.837305,1 11.452159,1 11.173945,1 11.022741,1.196444 11,1.310149 9.7584375,1.132091 9.4146607,1.77513 8.3540583,1.77513 7.1937825,1.77513 6.4186525,1.381759 6.4186525,1.381759 V 4.4097012 C 6.4186525,4.4097012 7.1937825,4.8708117 8.3540583,4.8708117 9.4107899,4.8708117 9.7656951,4.2321277 11,4.4077658 V 7.6074755 C 10.332769,7.6945688 9.9018991,7.9931051 9.9018991,8.3540583 9.9018991,8.4184105 9.9227047,8.4769566 9.951252,8.537438 L 5.5283658,10.306399 C 5.0483851,10.057215 4.4116366,10 3.710536,10 2.2135,10 1,10.595742 1,11.452159 1,12.306157 2.2135,13 3.710536,13 5.2070884,13 6.4186525,12.306157 6.4186525,11.452159 6.4186525,11.244103 6.3470425,11.045724 6.2164026,10.866215 L 10.766058,9.0459659 C 10.97363,9.0967703 11.204427,9 11.452159,9 12.306157,9 13,8.7812991 13,8.3540583 13,7.9931051 12.667231,7.6945688 12,7.6074755'
  ]
}