// Auto-generated file by generate-icons.js


export default {
  prefix: 'waypoint',
  iconName: 'cliff',
  icon: [
    14,
    14,
    [],
    "",
    'M 1.2216203,10.587294 H 1.2063531 C 1.092588,10.587294 1,10.679636 1,10.793647 1,10.907658 1.092588,11 1.2063531,11 H 12.793647 C 12.907658,11 13,10.907658 13,10.793647 13,10.679636 12.907658,10.587294 12.793647,10.587294 H 12.230731 L 11.004432,9.15932 10.13445,4.8103916 C 10.122137,4.7478456 10.081507,4.6946566 10.024624,4.6660916 L 8.7037675,4.0059096 C 8.6510712,3.9795616 8.58951,3.9770996 8.5348436,3.9987686 L 7.5350899,4.3989166 6.9650332,4.0187146 C 6.9123369,3.9835016 6.8458508,3.9746366 6.7855208,3.9945826 L 4.8044817,4.6550106 C 4.7360256,4.6779116 4.6845605,4.7347946 4.6685545,4.8049746 L 3.7311007,8.929574 3.5269638,9.625462 2.3624723,10.587294 H 1.6828367'
  ]
}