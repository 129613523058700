// Auto-generated file by generate-icons.js


export default {
  prefix: 'miscs',
  iconName: 'sort',
  icon: [
    14,
    14,
    [],
    "",
    'M4 .6L.3 4.3a1 1 0 001.4 1.4L3 4.4V12a1 1 0 102 0V4.4l1.3 1.3a1 1 0 001.4-1.4L4 .6zm6 .4a1 1 0 00-1 1v7.6L7.7 8.3a1 1 0 00-1.4 1.4l3.7 3.7 3.7-3.7a1 1 0 00-1.4-1.4L11 9.6V2c0-.6-.4-1-1-1z'
  ]
}