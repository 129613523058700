<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="-50 0 200 100">
    <path d="M5,50L50,5L95,50" fill="white" />
    <path d="M5,50L50,95L95,50" :fill="dangerFill" />
    <path d="m50,0l50,50-50,50-50-50zv5L21,33l19-10L50,26l9-8L80,38l2-1L50,5M5,50L50,95L95,50" />

    <g v-if="level === 0" stroke-width="5" stroke="black">
      <line x1="40" x2="60" y1="58" y2="78" />
      <line x1="40" x2="60" y1="78" y2="58" />
    </g>

    <path
      v-if="level === 1"
      d="m54 84h-6l-0.1-21.1c-2.3 2.1-4.9 4-8 5v-5.6c1.6-0.5 3.2-2 5.1-3.4 1.9-1.5 3.1-3 3.8-4.9h5z"
    />

    <path
      v-if="level === 2"
      d="m60 77v5.1l-20 0.1c0.2-2.1 1-3.9 2.1-5.7q1.6-2.7 6.4-7.2 3.9-3.6 4.8-4.9 1.2-1.8 1.2-3.5 0-2-1.1-3-1-1-2.8-1-1.8 0-2.8 1.1c-0.7 0.7-1.6 1.3-1.7 3h-5.9c0.4-3.1 2-5.5 3.8-6.8q2.7-2.1 6.7-2.1 4.5 0 7 2.4 2.6 2.4 2.6 5.9 0 2.1-0.8 3.9-0.7 1.8-2.3 3.8-1 1.4-3.7 3.9-2.8 2.5-3.5 3.3c-0.5 0.5-0.8 1.3-1.1 1.8 0 0 11.1-0.1 11.1-0.1z"
    />
    <path v-if="level === 2" d="M86,45a7,7,0,106.5-13.5a7,7,0,00-10.5-8a5.4,5.4,0,00-10.2,3" />
    <path v-if="level === 2" d="M69,27L86,44a5,5,0,104-12a5,5,0,10-9-5a3,3,0,10-8,0" fill="white" />
    <path v-if="level === 2" d="M85,32a1,1,0,110,8v-2a1,1,0,100-4" />

    <path
      v-if="level === 3"
      d="m58 81q-2.9 2.8-7.2 2.8-4.1 0-6.7-2.3c-1.8-1.6-2.1-4.2-2.4-6.7h5c0.2 1.4 0.5 2.4 1.2 3.1q1.2 1.1 2.9 1.1 1.7 0 3-1.3 1.2-1.4 1.2-3.7c0-1.4 0.1-1.6-0.7-2.4-0.8-0.9-1.3-1.8-2.7-1.8-1.4-0.1-2.6 0-3.9 0l0.1-4c3.3 0 3.9-1 4.7-1.7q1.2-1.1 1.2-2.8 0-1.5-0.9-2.4-0.9-0.9-2.3-0.9-1.5 0-2.5 1c-0.7 0.7-1.2 1.5-1.4 2.8h-5c0.4-1.8 0.7-4 1.5-5.1q1.1-1.6 3.1-2.6 2-0.9 4.4-0.9 4.3 0 6.8 2.7 2.1 2.2 2.1 5 0 3.9-4.3 6.3 2.6 0.5 4.1 2.5 1.6 1.9 1.6 4.6 0 3.9-2.9 6.7z"
    />

    <path v-if="level === 3" d="M54,22L79,50h33a5,5,0,10-4-22.3a11.3,11.3,0,00-20,0q-8-2,-14,6" />
    <path v-if="level === 3" d="M55,21L82,48h30a5,5,0,10-5-18a9.7,9.7,0,00-18,0q-7-2,-11,3L60,16" fill="white" />
    <path v-if="level === 3" d="M59,18l25,25-1.5,1.5-25-25M87,38a1,1,0,1110,5l-2-1a1,1,0,10-6-3" />

    <path
      v-if="level === 4"
      d="m60 78h-3l-0.1 6-4.9-0.1 0.1-5.9h-13.1v-4.9l13.1-19.1h4.8l0.1 19 3-0.1zm-7.9-4.9l-0.1-10.1-7.9 10.1z"
    />

    <path
      v-if="level === 5"
      d="m58 80q-2.8 3.8-7.9 3.8-4.1 0-6.6-2.2-2.6-2.1-3.1-5.8h5.6c0.1 1.2 0.7 1.7 1.4 2.4q1.2 1.1 2.7 1.1 1.8 0 3-1.4 1.2-1.4 1.2-4.3 0-2.7-1.2-4.1-1.2-1.3-3.1-1.3c-1.6 0-2.7 0.2-4 1.6h-5l3.1-15.9h14.9v4.9h-10.9l-1 6c1.2-0.6 2.8-1.1 4.1-1.1q3.7 0 6.3 2.7 2.6 2.8 2.6 7.1 0 3.6-2.1 6.5z"
    />

    <path
      v-if="level === 4 || level === 5"
      d="M54,22L79,50h52a12.5,12.5,0,001.5-24.4a7,7,0,00-8.2-7a11.9,11.9,0,00-22.5-2q-11-1,-14,7q-10-2-14,4"
    />
    <path
      v-if="level === 4 || level === 5"
      d="M52,24L77,48h50a5,5,0,103-21a5,5,0,00-7-6a10.3,10.3,0,00-20-2q-11-2,-14,7q-10-3-14,4L60,16"
      fill="white"
    />
    <path
      v-if="level === 4 || level === 5"
      d="M59,18l25,25-1.5,1.5-25-25m-1.5,1.5l22.5,22.5-1.5,1.5-22.5-22.5M87,38a1,1,0,1110,5l-2-1a1,1,0,10-6-3M96,29a1,1,0,1115,14l-1.7-1.7A1,1,0,1097.7,30.7"
    />
  </svg>
</template>
<script>
// #fff, #cbdb45, #fff100, #f6921e, #ec1c24, #ec1c24
const dangerFill = ['255, 255, 255', '203, 219, 69', '255, 241, 0', '246, 146, 30', '236, 28, 36', '236, 28, 36'];

export default {
  props: {
    size: {
      type: Number,
      required: false,
      default: 80,
    },
    level: {
      type: Number,
      required: true,
      default: 0,
    },
  },

  computed: {
    dangerFill() {
      return `rgb(${dangerFill[this.level]})`;
    },
  },
};
</script>
