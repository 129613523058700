import { render, staticRenderFns } from "./DocumentCard.vue?vue&type=template&id=ec571292&scoped=true"
import script from "./DocumentCard.vue?vue&type=script&lang=js"
export * from "./DocumentCard.vue?vue&type=script&lang=js"
import style0 from "./DocumentCard.vue?vue&type=style&index=0&id=ec571292&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ec571292",
  null
  
)

export default component.exports