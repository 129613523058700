// Auto-generated file by generate-icons.js


export default {
  prefix: 'waypoint',
  iconName: 'hut',
  icon: [
    14,
    14,
    [],
    "",
    'M 6.9998179,1 C 6.9321862,1 6.8646998,1.0179711 6.8047936,1.0538135 L 1.180539,4 C 1.0363304,4.0860219 0.96849492,4.3412315 1.0140345,4.5 1.059574,4.6587686 1.206959,5 1.3755638,5 H 12.624492 C 12.792554,5 12.940481,4.6587686 12.986021,4.5 13.031561,4.3412315 12.963183,4.0860219 12.819516,4 L 7.1948427,1.0538135 C 7.1349364,1.0179711 7.0674495,1 6.9998179,1 Z M 2,6 C 1.5950232,6 1.5626192,6.1033441 1.5626192,6.5 1.5626192,6.8966561 1.5950232,7.0123236 2,7.0123236 H 12 C 12.404976,7.0123236 12.437436,6.8966561 12.437436,6.5 12.437436,6.1033441 12.404976,6 12,6 Z M 2,8 C 1.5950232,8 1.5626192,8.1033441 1.5626192,8.5 1.5626192,8.8966558 1.5950232,9.0079418 2,9.0079418 H 12 C 12.404976,9.0079418 12.437436,8.8966558 12.437436,8.5 12.437436,8.1033441 12.404976,8 12,8 Z M 2,10 C 1.5950232,10 1.5622002,10.103344 1.5622002,10.5 1.5622002,10.896656 1.5950232,11.003971 2,11.003971 H 6 V 10 Z M 8,10 V 11.003971 H 12 C 12.404976,11.003971 12.437436,10.896656 12.437436,10.5 12.437436,10.103344 12.404976,10 12,10 Z M 2,12 C 1.5950232,12 1.5622002,12.103344 1.5622002,12.5 1.5622002,12.896656 1.5950232,13 2,13 H 6 V 12 Z M 8,12 V 13 H 12 C 12.404976,13 12.437436,12.896656 12.437436,12.5 12.437436,12.103344 12.404976,12 12,12 Z'
  ]
}