// Auto-generated file by generate-icons.js


export default {
  prefix: 'activity',
  iconName: 'ice_climbing',
  icon: [
    64,
    65,
    [],
    "",
    'M32 5a28 28 0 1 0 0 56 28 28 0 0 0 0-56zm-4.8 1.4c.9 1 .7 3.5-.9 8l-.8 1.6-.2.3-.2.3 1 .8c1.5 1.3 1.3 3.4 1.3 3.4l-.2 1.1c.3.4.4 1 .5 1.3l1 .9c.6.6 1 1.5 1.6 2.2l1.1 1.5v-.1a6 6 0 0 1-.3-2v-.2c-.3-.6-.3-1-.3-2v-.8l-.3-.3c-.7-.3-.8-.9-1-1-.2-.2.1-.3 0-.4-.1-.2.1-.3 0-.4V20c.1-.3.1-.4.4-.4 1-.1 1.4.3 1.4.3l.4-.5c1-1.4.4-3.3.4-3.3l-1-2.5c-.6-.6-1 0-1.2.1l-2.3 1v-.2c1.3-.6 3-2.2 3-2.2l2.6-.2-.1.2-1.4.5.1.6 1.1 2.9c.7 1.9-.6 3.6-.6 3.6l-.6.8.1.3c.4.5 1.2 1.3 1.4 1.8.2.5.2 1.8.5 2.2l.4 1.3c.1.4.4.9.7 1.2l.5.2h.4c.2 0 .5-.4.5-.4.2-.8 1.4-1.1 1.4-1.1 1.8-1 3.2 1.4 3.2 1.4 1 1.7-1.1 3.4-1.7 3.9-.2 0-.2.2-.3.3 0 .3.1.8.7 1.5 1 1.2 0 2.6-.5 2.8-.6.2-.3 0-.5.8-.3.9-1 2-2.1 2.7-1.2.6-.7 4.7-.4 5.6.2.5-.5 2.5-.8 3.3-.2.7-.2 2.3-.2 2.9l.1.4c.6.5.8 1.8 1 2.6l1.1 3.8c.1.6-.3 1.1-.8 1.4l-1.6.2c-.3 0-.4-.3-.3-.5l-.2-1.4c0-.3-.7-1.8-1-2.4l-.6-1.4c0-.2-.2-.3-.3-.4-.3 0-.4-.3-.6-.5l-.2-.4c.3-3.8-.6-5.6-.6-5.6l-.1-.4c-.8 1-2.2 1.5-2.2 1.5s-.1-.3 0 .1l1.2 3.9.4 2.5c.1.7.2.7.6 1.2.3.6-.1.8-.6 1.6-.5.7-1 .3-1.6.3H26 26l.6 1.5a27 27 0 0 1-14.5-8.2L9 47A26.9 26.9 0 0 1 5 29.7l.5-2.7c.6-2.3 1.4-4.6 2.5-6.6v-.1l.2-.3C12 13 19 7.8 27.2 6.4zm-2.4 10.9l-3 5.3c-1.5 2.9-2.7 6-3.6 7.4l-1 1.6v.7h.2c.5.4.3 1 .2 1.8l1.2 6.3c1.4 4.6 3.7 11.7 4.8 13.6l1.7 2.6c.3-.5 1-.9 1.2-1.1.4-.4.6-.5 1-1.2.4-.8-.8-3.2-1.1-3.8-.3-.6-.1-.8-.6-1.2-.5-.4-.6-1-.7-1.6 0-.6.3-1.1 1-2 .5-.6 2-1.8 2.6-2.3l.2-.6c-.2-.6.4-.5.4-1.9v-.1c.2-1.6 1.2-2.7 1.3-3.4l.6-2.2c.3-1.1.8-1.3 1-2.2.3-.9-.6-1-1-1.4L29 29.3c-.6-.6-.7-1-.8-1.3v-.3c-.3-.3-.4-.7-.8-1.5-.4-1-.7-1-.7-1-1-.3-1-1.3-1.2-1.7a3 3 0 0 1 0-1.3c.1-.4.3-.6.6-.6h.3l.1-.9c.3-1.7-1.3-3-1.3-3z'
  ]
}